import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import TabPanel from './appTabPanel';
import Tabs from './appTabBar';
import _t from '../../utils/helper';
import FreeTextQr from '../QRComponent/freeTextQr/freeTextQrV2';
import ListQr from '../QRComponent/myQrCode/listQrCodeV2';
import EmailQr from '../QRComponent/emailQR/emailQrV2';

const FreeTextQrIcon = require('../../images/FreeText_qr_icon.svg').default;
const EmailQrIcon = require('../../images/Email_qr_icon.svg').default;
const ListQrIcon = require('../../images/ListQr_icon.svg').default;
const FreeTextQrIconDisabled = require('../../images/FreeText_qr_icon_disabled.svg').default;
const EmailQrIconDisabled = require('../../images/Email_qr_icon_disabled.svg').default;
const ListQrIconDisabled = require('../../images/ListQr_icon_disabled.svg').default;

function tabProps(index) {
  return {
    id: `qr-tab-${index}`,
    'aria-controls': `qr-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const { userID, userInfo, userQrCodeData } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function renderFreeTextQrIcon() {
    return (
      <img
        src={value === 0 ? FreeTextQrIcon : FreeTextQrIconDisabled}
        alt="Icon for Free text Qr tab"
        className="TabIcon"
      />
    );
  }

  function renderEmailQrIcon() {
    return (
      <img src={value === 1 ? EmailQrIcon : EmailQrIconDisabled} alt="Icon for Email QR tab" className="TabIcon" />
    );
  }

  function renderListQrIcon() {
    return (
      <img src={value === 3 ? ListQrIcon : ListQrIconDisabled} alt="Icon for List Qr tab" className="TabIcon" />
    );
  }

  return (
    <>
      <Tabs onChange={handleChange} value={value}>
        <StyledTab
          icon={renderFreeTextQrIcon()}
          iconPosition="start"
          label={_t('qr_freeTextQrCode', 'Free Text QR Code')}
          {...tabProps(0)}
        />
        <StyledTab
          icon={renderEmailQrIcon()}
          iconPosition="start"
          label={_t('qr_emailQrCode', 'Email QR Code')}
          {...tabProps(1)}
        />
        <StyledDivider>
          <Typography sx={{ fontWeight: '100', fontSize: '20px' }} color="rgba(0, 0, 0, 0.6)">
            |
          </Typography>
        </StyledDivider>
        <StyledTab
          icon={renderListQrIcon()}
          iconPosition="start"
          label={_t('qr_myQrCode', 'My QR Code')}
          {...tabProps(2)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <FreeTextQr userID={userID} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmailQr userID={userID} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ListQr userInfo={userInfo} data={userQrCodeData} />
      </TabPanel>
    </>
  );
}

const StyledDivider = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: '20px',
  },
  [theme.breakpoints.up('tablet')]: {
    alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: '32px',
  },
  [theme.breakpoints.up('desktop')]: {
    alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: '32px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: '32px',
  },
}));

const StyledTab = styled(Tab)(() => ({
  fontWeight: 400,
  fontSize: '14px',
}));

BasicTabs.propTypes = {
  userID: PropTypes.any,
  userInfo: PropTypes.any,
  userQrCodeData: PropTypes.any,
};
