import { createTheme } from '@mui/material/styles';
import palette from './palette';

const createCustomTheme = (theme) => createTheme({
  breakpoints: {
    values: {
      mobile: 0, // xs, extra-small: 0px
      tablet: 640, // sm, small: 600px
      laptop: 1024, // md, medium: 900px
      desktop: 1200, // lg, large: 1200px
      xldesktop: 1536, // xl, extra-large: 1536px
    },
  },
  palette: {
    primary: {
      main: palette[theme].dark,
      light: palette[theme].light,
      dark: palette[theme].dark,
      accent: palette[theme].accent,
      lightAccent: palette[theme].lightAccent,
      contrastText: palette[theme].contrastText,
    },
    secondary: {
      main: palette[theme].light,
      light: palette[theme].light,
      dark: palette[theme].dark,
      contrastText: palette[theme].contrastText,
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Helvetica Neue',
      'BlinkMacSystemFont',
      'Roboto',
      'Heebo',
      'Segoe UI',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: palette.black.main,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: palette.black.main,
        },
      },
    },
    /* MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: palette[theme].contrastText
          }
        }
      } */
  },
});

const red = createCustomTheme('red');

const themes = {
  red,
};

export default themes;
