import React, { Component } from 'react';
import {TailSpin} from "react-loader-spinner";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppTheme from '../../common/themes';
import ExperimentalAppBar from '../../components/appBar/appBarChild';
import AppBarDrawer from '../../components/appDrawer/appDrawerList';
import AppTabV2 from '../../components/appTabBar'
import Palette from '../../common/palette';

class Home extends Component {
  state = {
    isDrawerOpen: false,
  };

  componentDidMount() {
    this.props.checkSession();
    this.props.getEmpID(this.props.userInfo && this.props.userInfo.username);
  }

  componentDidUpdate() {
    this.props.getEmpID(this.props.userInfo && this.props.userInfo.username);
  }

  showLoader() {
    return (
      <TailSpin
      height={100}
      width={100}
      color={Palette.red.dark}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
    );
  }

  toggleDrawer(value) {
    this.setState({
      isDrawerOpen: value,
    });
  }

  render() {
    return (
      <ThemeProvider theme={AppTheme['red']}>
        <CssBaseline />
        <Box>
          <ExperimentalAppBar 
          toggleDrawer={() => this.toggleDrawer(true)} 
          userID={this.props.userID && this.props.userID}
          userInfo={this.props.userInfo && this.props.userInfo}
          />
          <AppBarDrawer
            isDrawerOpen={this.state.isDrawerOpen}
            toggleDrawer={() => this.toggleDrawer(false)}
            userInfo={this.props.userInfo && this.props.userInfo}
          />
          <AppTabV2/>
        </Box>
      </ThemeProvider>
    );
  }
}

export default Home;

Home.propTypes = {
  checkSession: PropTypes.any,
  getEmpID:PropTypes.any,
  userInfo:PropTypes.any, 
  userID:PropTypes.string,
};
