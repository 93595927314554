import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import _t from '../../utils/helper';

const userManual = require('../../images/UserManual.svg').default;

const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '56px',
  borderBottom: '1px solid',
  borderColor: '#D9D9D9',
  backgroundColor: theme.palette.primary.lightAccent,
  [theme.breakpoints.up('mobile')]: {
    padding: '0px 20px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '0px 48px',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '0px 48px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    padding: '0px 48px',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '',
  justifyContent: 'space-between',
  alignItem: 'center',
  width: '100%',
  '& .MuiTab-root': {
    minHeight: '56px',
    minWidth: '0px',
  },
  [theme.breakpoints.up('mobile')]: {
    maxWidth: '415px',
    padding: '0px 0px',
  },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '850px',
    padding: '0px 0px 0px 0px',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '1080px',
    padding: '0px 48px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    maxWidth: '1550px',
    padding: '0px 48px',
  },
}));

const UsermanualButton = styled(Button)(({ theme }) => ({
  backgroundColor: '',
  [theme.breakpoints.up('mobile')]: {
    display: 'none',
  },
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('xldesktop')]: {
    display: 'flex',
  },
  color: theme.palette.primary.accent,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '',
  [theme.breakpoints.up('mobile')]: {
    '& .MuiTab-root': {
      minHeight: '48px',
      padding: '12px 0px',
      marginRight: '20px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  [theme.breakpoints.up('tablet')]: {
    '& .MuiTab-root': {
      minHeight: '56px',
      padding: '12px 0px',
      marginRight: '32px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  [theme.breakpoints.up('desktop')]: {
    '& .MuiTab-root': {
      minHeight: '56px',
      padding: '12px 0px',
      marginRight: '32px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  [theme.breakpoints.up('xldesktop')]: {
    '& .MuiTab-root': {
      minHeight: '56px',
      padding: '12px 2px',
      marginRight: '32px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
}));

export default function AppTabs(props) {
  function userManualIcon() {
    return (
      <img
        src={userManual}
        style={{
          height: '16px',
          width: '22px',
        }}
        alt="userManualIcon"
      />
    );
  }

  return (
    <MainWrapper>
      <StyledBox>
        <StyledTabs
          value={props.value}
          onChange={props.onChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="Qrcode Tabs"
        >
          {props.children}
        </StyledTabs>
        <UsermanualButton
          startIcon={userManualIcon()}
          onClick={() => {
            window.open(
              'https://az.box.com/s/tkxauc02wfdnxf2l9vs7j4i4aa0f1222',
              '_blank',
            );
          }}
        >
          <Typography
            fontSize="14px"
            fontWeight="400"
          >
            {_t('qr_userManual', 'User Manual')}
          </Typography>
        </UsermanualButton>
      </StyledBox>
    </MainWrapper>
  );
}

AppTabs.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.any,
  children: PropTypes.any
};
