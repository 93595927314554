import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import LoginScreen from '../screen/Login';
import HomeScreen from '../screen/Home/index';
import AppLayout from '../layout/appLayout';

import LoginRoute from './loginRoute.jsx';
import PrivateRoute from './privateRoute.jsx';

function AppRoute() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/home" exact component={HomeScreen} />
        <LoginRoute path="/login" exact component={LoginScreen} />
        <LoginRoute path="/" exact component={LoginScreen} />
        <Route path="/app" exact component={AppLayout} />
        <Route path="*" exact render={() => ' PAGE NOT FOUND'} />
      </Switch>
    </BrowserRouter>
  );
}

export default AppRoute;
