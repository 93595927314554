import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchUserInformation } from '../../redux/action/qrCodeAction';
import ApplicationTab from './appTabBarChild';

const mapStateToProps = (state) => ({
  isSignedIn: state.QR_CodeReducer.isLoggedin,
  userInfo: state.QR_CodeReducer.loggedInUser,
  userQrCodeData: state.QR_CodeReducer.userQrCodeData,
  userID: state.QR_CodeReducer.empID,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUserInformation }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationTab),
);
