/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TranslateIcon from '@mui/icons-material/Translate';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DownloadButton from '../appButton/appDownload';
import AppDrawer from './appDrawer';
import appConfig from '../../config/appConfig';
import { downloadBusinessCardData } from '../../services/api';
import Appmodal from '../appModal/appModal';
import _t from '../../utils/helper';
import AppDropDown from '../appDropdown/appDropdown';

const userManual = require('../../images/UserManual_FFA4A2.svg').default;

function RenderAppDrawerList(props) {
  const [warningState, setWarningState] = React.useState(false);
  const [downloadMessage, setDownloadMessage] = React.useState(
    _t('qr_download', 'Download Business Card QR Code'),
  );

  const {
    userInfo, isDrawerOpen, toggleDrawer, userID,
  } = props;

  // Get the Avatar Alias Name
  const getAlias = () => {
    const AliasName = userInfo
      ? `${userInfo.given_name[0]}${userInfo.family_name[0]}`
      : '';
    return AliasName;
  };

  const PullUserID = () => {
    const userName = userInfo.username;
    const splitUserName = userName.split('_');
    const extractId = splitUserName[1];
    return extractId;
  };

  // Logic to Download the Business card
  const DownloadBusinessCard = () => {
    const UID = userID || PullUserID();
    const crqrURL = `${appConfig.crqrURL}?`;
    const requestType = 'getcode';
    const qrcodeType = 'vcard';
    const action = 'download';
    const qrcodeContent = null;
    const fileName = null;
    const empid = UID;
    const emailTo = null;
    const emailTittle = null;
    const emailBody = null;
    const now = `${crqrURL}Request_type=${requestType}&qrcode_type=${qrcodeType}&qrcode_content=${qrcodeContent}&file_name=${fileName}&empid=${empid}&emailTo=${emailTo}&emailTittle=${emailTittle}&emailBody=${emailBody}&action=${action}`;
    // window.location.href = now;
    setDownloadMessage(_t('qr_download_biz_card', ''));
    downloadBusinessCardData(now)
      .then((response) => {
        const { data, headers } = response;
        if (headers['content-type'] === 'application/json') {
          setWarningState(true);
        } else {
          const fileNameDownload = headers['qr-code-filename-download'];
          const imageData = `data:image/png;base64,${Buffer.from(
            data,
            'binary',
          ).toString('base64')}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = imageData;
          downloadLink.download = fileNameDownload;
          downloadLink.click();
          setWarningState(false);
        }
      })
      .catch((error) => {
        console.log('Error Ocurred When Downloading', error);
      })
      .then(() => {
        setDownloadMessage(_t('qr_download', 'Download Business Card QR Code'));
      });
  };

  function userManualIcon() {
    return (
      <img
        src={userManual}
        style={{
          height: '15px',
          width: '22px',
        }}
        alt="userManualIcon"
      />
    );
  }

  const appDrawerList = () => (
    <List>
      <ListItem disableGutters>
        <ListItemIcon sx={{ marginLeft: 'auto', color: 'primary.lightAccent' }} onClick={() => toggleDrawer(false)}>
          <CloseIcon />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <ListItemButton>
        <ListItemIcon>
          <Avatar
            sx={{
              height: '24px',
              width: '24px',
              bgcolor: 'primary.lightAccent',
              color: 'primary.dark',
            }}
          >
            <Typography
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"20px"}
              letterSpacing="0.14px"
            >
              {getAlias()}
            </Typography>
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={userInfo.email}
          primaryTypographyProps={{ color: 'primary.lightAccent', fontSize:"14px" }}
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <TranslateIcon color="secondary" />
        </ListItemIcon>
        <AppDropDown />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          window.open(
            'https://az.box.com/s/tkxauc02wfdnxf2l9vs7j4i4aa0f1222',
            '_blank',
          );
          toggleDrawer(false);
        }}
      >
        <ListItemIcon>
          {userManualIcon()}
        </ListItemIcon>
        <ListItemText
          primary={_t('qr_userManual', 'User Manual')}
          primaryTypographyProps={{ color: 'primary.lightAccent', fontSize:"14px" }}
        />
      </ListItemButton>
      <Divider
        sx={{
          display: {
            mobile: 'flex',
            laptop: 'none',
            tablet: 'none',
            desktop: 'none',
          },
        }}
      />

      <ListItem>
        <Box sx={{ margin: 'auto', width: '100%' }}>
          <DownloadButton
            title={downloadMessage}
            smallScreenRender="flex"
            largeScreenRender="none"
            customColor="#FFEAE5"
            showTitle
            fullWidth
            fontSize="14px"
            customHeight="36px"
            onClick={() => DownloadBusinessCard()}
          />
        </Box>
      </ListItem>
    </List>
  );

  return (
    <>
      <AppDrawer
        openDrawer={isDrawerOpen}
      // closeDrawer={() => toggleDrawer(false)}
        position="right"
        listItem={appDrawerList()}
      />
      <Appmodal
        open={warningState}
        closeModal={() => setWarningState(false)}
        title={_t('qr_attention', '')}
        description={_t('qr_no_business_card_warning', '')}
        buttonTitle={_t('qr_close_button', '')}
      />
    </>
  );
}

RenderAppDrawerList.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  userID: PropTypes.any,
};

export default RenderAppDrawerList;
