const AppConfig = {
  domain: process.env.REACT_APP_COGNITO_DOMAIN,
  region: process.env.REACT_APP_REGION,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  appClientId: process.env.REACT_APP_CLIENT_ID,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  redirectURI: process.env.REACT_APP_REDIRECT_URI,
  searchParameter: process.env.REACT_APP_SEARCH_PARAMETER,
  crqrURL: process.env.REACT_APP_CRQR_URL,
  authorizationToken: process.env.REACT_APP_AUTHORIZATION_TOKEN,
};
export default AppConfig;
