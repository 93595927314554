import Axios from 'axios';
import AppConfig from '../config/appConfig';

const { domain, crqrURL, authorizationToken } = AppConfig;

export function getUserInfo(token) {
  return Axios({
    method: 'get',
    url:
            `https://${domain}/oauth2/userInfo`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function downloadBusinessCardData(url) {
  return Axios({
    headers: { authorizationToken },
    method: 'get',
    url,
    responseType: 'arraybuffer',
  });
}

export function listAllQrCode(empID) {
  return Axios({
    headers: { authorizationToken },
    method: 'get',
    url: `${AppConfig.crqrURL}?Request_type=getcode&qrcode_type=all&qrcode_content=null&file_name=null&empid=${empID}&emailTo=null&emailTittle=null&emailBody=null&action=null`,
  });
}

export function getQrCode(empID, file_name) {
  return Axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      authorizationToken,
    },
    responseType: 'arraybuffer',
    data: {
      file_name,
    },
    url: `${AppConfig.crqrURL}?Request_type=getcode&qrcode_type=all&qrcode_content=null&empid=${empID}&emailTo=null&emailTittle=null&emailBody=null&action=null`,
  });
}

export function createqr(content, dlFileName, empID) {
  const URL = crqrURL;
  const Request_type = 'createcode';
  const qrcode_type = 'freetext';
  const action = 'create';
  const qrcode_content = content;
  const file_name = dlFileName;
  const empid = empID;
  const emailTo = null;
  const emailTittle = null;
  const emailBody = null;
  const createQRcodeURL = `${URL}?Request_type=${Request_type}&qrcode_type=${qrcode_type}&empid=${empid}&emailTo=${emailTo}&emailTittle=${emailTittle}&emailBody=${emailBody}&action=${action}`;
  return Axios({
    method: 'post',
    url: createQRcodeURL,
    headers: {
      'Content-Type': 'application/json',
      authorizationToken,
    },
    responseType: 'arraybuffer',
    data: {
      qrcode_content, file_name,
    },
  });
}

export function downloadFreeTextQr(content, dlFileName, empID) {
  const crqrURL = `${AppConfig.crqrURL}?`;
  const Request_type = 'createcode';
  const qrcode_type = 'freetext';
  const action = 'download';
  const qrcode_content = content;
  const file_name = dlFileName;
  const empid = empID;
  const emailTo = null;
  const emailTittle = null;
  const emailBody = null;
  const downloadFreeTextQrCode = `${crqrURL}Request_type=${Request_type}&qrcode_type=${qrcode_type}&empid=${empid}&emailTo=${emailTo}&emailTittle=${emailTittle}&emailBody=${emailBody}&action=${action}`;

  return Axios({
    method: 'post',
    url: downloadFreeTextQrCode,
    headers: {
      'Content-Type': 'application/json',
      authorizationToken,
    },
    responseType: 'arraybuffer',
    data: {
      qrcode_content, file_name,
    },
  });
}

export function createEmailqr(to, subject, body, userID, fileNameToDownload) {
  const crqrURL = `${AppConfig.crqrURL}?`;
  const Request_type = 'createcode';
  const qrcode_type = 'email';
  const action = 'create';
  const file_name = fileNameToDownload;
  const empid = userID;
  const emailTo = to;
  const emailTittle = subject;
  const emailBody = body;
  const createEmailQRcode = `${crqrURL}Request_type=${Request_type}&qrcode_type=${qrcode_type}&empid=${empid}&action=${action}`;
  return Axios({
    method: 'post',
    url: createEmailQRcode,
    headers: {
      'Content-Type': 'application/json',
      authorizationToken,
    },
    responseType: 'arraybuffer',
    data: {
      file_name, emailBody, emailTittle, emailTo,
    },
  });
}

export function downloadEmailqr(to, subject, body, userID, fileNameToDownload) {
  const crqrURL = `${AppConfig.crqrURL}?`;
  const Request_type = 'createcode';
  const qrcode_type = 'email';
  const action = 'download';
  const file_name = fileNameToDownload;
  const empid = userID;
  const emailTo = to;
  const emailTittle = subject;
  const emailBody = body;
  const downloadFreeTextQrCode = `${crqrURL}Request_type=${Request_type}&qrcode_type=${qrcode_type}&empid=${empid}&action=${action}`;

  return Axios({
    method: 'post',
    url: downloadFreeTextQrCode,
    headers: {
      'Content-Type': 'application/json',
      authorizationToken,
    },
    responseType: 'arraybuffer',
    data: {
      file_name, emailBody, emailTittle, emailTo,
    },
  });
}
