import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';


function PrivateRoute({ component: Component, isLoggedin: IsLoggedin, ...rest }) {
  const isLoggedin = useSelector((state) => state.QR_CodeReducer.isLoggedin);
  return (
    <Route
      {...rest}
      render={(props) => (isLoggedin ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      ))}
    />
  );
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isLoggedin:PropTypes.bool,
}
