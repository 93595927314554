import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Login from './Login.jsx';

const mapStateToProps = (state) => ({

});

export default withRouter(
  connect(
    mapStateToProps,
  )(Login),
);
