import React from 'react';
import NativeSelect from '@mui/material/NativeSelect';
import { useDispatch, useSelector } from 'react-redux';
import { changeLang } from '../../redux/action/qrCodeAction';

export default function App() {
  const dispatch = useDispatch();
  const presentLanguage = useSelector((state) => state.QR_CodeReducer.language);

  function changeLanguage(e) {
    const { value } = e.target;
    dispatch(changeLang(value));
  }

  return (
    <NativeSelect
      inputProps={{
        name: 'languageSelector',
        id: 'languageSelector',
      }}
      sx={{
        color: 'white',
        fontSize:"15px" ,
        '& .MuiNativeSelect-icon': {
          color: 'white',
        },
      }}
      value={presentLanguage}
      onChange={(e) => changeLanguage(e)}
    >
      <option value="en" style={{ color: 'black', fontSize:"15px" }}>English</option>
      <option value="ja" style={{ color: 'black',fontSize:"15px" }}>日本語</option>
    </NativeSelect>
  );
}
