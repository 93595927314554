import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

export default function appDrawer(props) {
  return (
    <Box>
      {/* <Drawer
        anchor={props.position}
        open={props.openDrawer}
        onClose={() => props.closeDrawer(false)}
      >
        <Box sx={{ width: 'auto' }} onClick={() => props.closeDrawer(false)}>
          {props.listItem}
        </Box>
      </Drawer> */}
      <Drawer
        anchor={props.position}
        open={props.openDrawer}
      >
        <Box sx={{ width: 'auto' }}>
          {props.listItem}
        </Box>
      </Drawer>
    </Box>
  );
}
