import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
//Import Bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle'

//Redux Setup
import { Provider } from 'react-redux';
import Store from './redux/store/store';
import { persistStore } from 'redux-persist';

localStorage.setItem("lang", "ja");
const container = document.getElementById('root');
const root = createRoot(container);
persistStore(Store, {}, () => {
  root.render(
    <Provider store={Store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>,
  );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
