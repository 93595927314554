import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';

const BoxStyled = styled(Box)(() => ({
  flexGrow: 1,
}));

export default function headerBar({ leftChildren, rightChildren }) {
  return (
    <BoxStyled>
      <AppBar position="static">
        <Container>
          <Toolbar>
            <Stack direction="row" spacing={1} flexGrow={1} alignItems="center">
              {React.cloneElement(leftChildren)}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              {React.cloneElement(rightChildren)}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </BoxStyled>
  );
}
