import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  [theme.breakpoints.up('mobile')]: {
    maxWidth: '350px',
    padding: '20px',
  },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '500px',
    padding: '32px',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '650px',
    padding: '32px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    maxWidth: '650px',
    padding: '32px',
  },
}));

const BodyBox = styled(Box)(() => ({
  marginBottom: '32px',
  marginTop: '32px',
  width: '100%',
}));

const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
}));

const ModelTitle = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20px',
}));

const ModelDescription = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export default function AppModal(props) {
  const {
    open, closeModal, title, description, buttonTitle,
  } = props;
  return (
    <Modal
      open={open}
      aria-labelledby="Qr Code Modal"
      aria-describedby="Qr Code Modal Window if Business Card not available"
    >
      <ModalBox>
        <Box>
          <ModelTitle id="modal-title">
            {title}
          </ModelTitle>
        </Box>
        <BodyBox>
          <ModelDescription>
            {description}
          </ModelDescription>
        </BodyBox>
        <ButtonBox>
          <Button variant="outlined" onClick={() => closeModal()}>
            {buttonTitle}
          </Button>
        </ButtonBox>
      </ModalBox>
    </Modal>
  );
}

AppModal.propTypes = {
  open:PropTypes.any, 
  closeModal:PropTypes.any,
  title:PropTypes.any,
  description:PropTypes.any,
  buttonTitle:PropTypes.any,
};
