import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'QR',
  storage,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appReducer = combineReducers(require('../reducer').default);

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = createStore(persistedReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunk),
));

export default store;
