export function getUrlParameter() {
  const params = {};
  const regex = /([^&=]+)=([^&]*)/g; let
    m;
  const fragmentString = window.location.hash.substring(1);
  while ((m = regex.exec(fragmentString))) {
    params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
  }
  if (Object.keys(params).length > 0) {
    return params;
    // if (params['state'] && params['state'] == 'state_parameter_passthrough_value') {
    //  this. trySampleRequest();
    // }
  }
}

export function getUserID(username) {
  const splitString = username.split('_');
  return splitString[1];
}

export function checkSession() {
  const now = new Date();
  const itemStr = localStorage.getItem('key');
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  if (now.getTime() > item.expiry) {
    localStorage.removeItem('key');
  } else {
  }
}
