const palette = {
  black: {
    main: '#212121',
    contrastText: '#E57373',
  },
  red: {
    main: '#E57373',
    light: '#FFA4A2',
    dark: '#AF4448',
    lightAccent: '#FFEAE5',
    accent: '#F06292',
    contrastText: '#E57373',
  },
};

export default palette;
