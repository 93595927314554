import * as React from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { TailSpin } from 'react-loader-spinner';
import { createEmailqr, downloadEmailqr } from '../../../services/api';
import _t from '../../../utils/helper';
import DownloadButton from '../../appButton/appDownload';
import Palette from '../../../common/palette';
import PropTypes from 'prop-types';


const barcodeManual = require('../../../images/defaultQrCodeDisabled.png').default;

const validationSchema = Yup.object({
  Email: Yup.string().email(_t('qr_errormsg_to', '')).required(_t('qr_errormsg_to', '')),
  Subject: Yup.string().required(_t('qr_errormsg_subject', '')),
  Body: Yup.string().required(_t('qr_errormsg_body', '')),
  Filename: Yup.string().required(_t('qr_errormsg_filename_empty', '')),
});

export default function EmailQrCode(props) {
  const intl = useIntl();
  const [loader, setLoadervalue] = React.useState(false);
  const [qrCodeURL, setQRCodeURL] = React.useState(barcodeManual);
  const { userID } = props;

  const formik = useFormik({
    initialValues: {
      Email: '',
      Subject: '',
      Body: '',
      Filename: '',
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  function generateEmailTextQR(values) {
    setLoadervalue(true);
    createEmailqr(
      values.Email,
      values.Subject,
      values.Body,
      userID,
      values.Filename,
    ).then((response) => {
      setQRCodeURL(`data:image/png;base64,${Buffer.from(response.data, 'binary').toString('base64')}`);
      setLoadervalue(false);
    }).catch((error) => {
      alert('Error in QR CODE Generation');
    });
  }
  function downloadEmailText(values) {
    setLoadervalue(true);
    downloadEmailqr(
      values.Email,
      values.Subject,
      values.Body,
      userID,
      values.Filename,
    ).then((response) => {
      const { data } = response;
      // const fileName = headers['qr-code-filename-download'];
      const imageData = `data:image/png;base64,${Buffer.from(data, 'binary').toString('base64')}`;
      setQRCodeURL(imageData);
      const downloadLink = document.createElement('a');
      downloadLink.href = imageData;
      downloadLink.download = `Email_${values.Filename}`;
      downloadLink.click();
      setLoadervalue(false);
    }).catch((error) => {
      alert('Error in QR CODE Download');
    });
  }

  function showLoader() {
    return (
      <TailSpin
        height="inherit"
        width="inherit"
        color={Palette.red.dark}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible
      />
    );
  }

  function renderQRCode() {
    if (loader) {
      return showLoader();
    }
    return (
      <img
        alt=""
        src={qrCodeURL}
        style={{ width: '100%', height: '100%', position: 'relative' }}
      />
    );
  }

  return (
    <JumboBox>
      <StyledBox>
        <Grid container>
          <Grid
            item
            mobile={12}
            laptop={8}
            tablet={8}
            desktop={8}
            xldesktop={8}
          >
            <LeftInputBox>
              <LeftInnerContent>
                <Typography
                  fontSize="13px"
                  fontWeight="400"
                  color="text.secondary"
                >
                  <FormattedMessage
                    id="qr_field_members_msg"
                    defaultMessage=""
                    values={{
                      boldify: (chunks) => (
                        <span style={{ fontWeight: 'bold' }}>{chunks}</span>
                      ),
                    }}
                  />
                  <br />
                  <FormattedMessage
                    id="qr_field_members_msg_mail"
                    defaultMessage=""
                    values={{
                      link: (chunks) => (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={intl.formatMessage({
                            id: 'qr_field_members_msg_mail_link',
                          })}
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </Typography>
                <Box sx={{ marginTop: '32px' }}>
                  <FormControl fullWidth variant="outlined">
                    <Typography
                      fontSize="13px"
                      fontWeight="400"
                      color="text.primary"
                      mb="8px"
                    >
                      {_t('qr_to', 'To')}
                    </Typography>
                    <OutlinedInput
                      name="Email"
                      id="Email"
                      label="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disableAnimation
                      error={
                        formik.touched.Email && Boolean(formik.errors.Email)
                      }
                      placeholder={intl.formatMessage({
                        id: 'qr_placeholder_to',
                      })}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        height: '56px',
                      }}
                      size="small"
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="form-field-validation-error">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </FormControl>
                </Box>
                <Box sx={{ marginTop: '32px' }}>
                  <FormControl fullWidth variant="outlined">
                    <Typography
                      fontSize="13px"
                      fontWeight="400"
                      color="text.primary"
                      mb="8px"
                    >
                      {_t('qr_subject', 'Subject')}
                    </Typography>
                    <OutlinedInput
                      name="Subject"
                      id="Subject"
                      label="Subject"
                      value={formik.values.Subject}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disableAnimation
                      error={
                        formik.touched.Subject && Boolean(formik.errors.Subject)
                      }
                      placeholder={intl.formatMessage({ id: 'qr_subject' })}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        height: '56px',
                      }}
                      size="small"
                    />
                    {formik.touched.Subject && formik.errors.Subject ? (
                      <div className="form-field-validation-error">
                        {formik.errors.Subject}
                      </div>
                    ) : null}
                  </FormControl>
                </Box>
                <Box sx={{ marginTop: '32px' }}>
                  <FormControl fullWidth variant="outlined">
                    <Typography
                      fontSize="13px"
                      fontWeight="400"
                      color="text.primary"
                      mb="8px"
                    >
                      {_t('qr_body', 'Body')}
                    </Typography>
                    <OutlinedInput
                      name="Body"
                      id="Body"
                      multiline
                      maxLength={300}
                      rows={3}
                      label="Body"
                      value={formik.values.Body}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disableAnimation
                      error={formik.touched.Body && Boolean(formik.errors.Body)}
                      placeholder={intl.formatMessage({
                        id: 'qr_placeholder_body',
                      })}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                      }}
                      size="small"
                    />
                    {formik.touched.Body && formik.errors.Body ? (
                      <div className="form-field-validation-error">
                        {formik.errors.Body}
                      </div>
                    ) : null}
                    <StyledButton
                      variant="outlined"
                      sx={{ marginTop: '32px', height: '42px', border:'1px solid' }}
                      onClick={() => generateEmailTextQR(formik.values)}
                      disabled={(!formik.values.Email || !!formik.errors.Email)
                      || (!formik.values.Subject || !!formik.errors.Subject)
                      || (!formik.values.Body || !!formik.errors.Body)}
                    >
                      <Typography
                        fontSize="15px"
                        fontWeight="400"
                      >
                        {_t('qr_create', 'Create')}
                      </Typography>
                    </StyledButton>
                  </FormControl>
                </Box>
              </LeftInnerContent>
            </LeftInputBox>
          </Grid>
          <Grid
            item
            mobile={12}
            laptop={4}
            tablet={4}
            desktop={4}
            xldesktop={4}
          >
            <RghtOutputBox>
              <CoreBox>
                <EpicenterBox>{renderQRCode()}</EpicenterBox>
              </CoreBox>
              <DownloadFormBox>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    name="Filename"
                    id="Filename"
                    label="Filename"
                    value={formik.values.Filename}
                    onChange={formik.handleChange('Filename')}
                    onBlur={formik.handleBlur('Filename')}
                    disableAnimation
                    error={
                      formik.touched.Filename && Boolean(formik.errors.Filename)
                    }
                    placeholder={intl.formatMessage({ id: 'qr_fileName' })}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      "& ::placeholder": {
                        textOverflow: "ellipsis !important",
                        fontSize: {
                          tablet: "12px",
                          mobile: "16px",
                          laptop: "16px",
                          desktop: "16px",
                        },
                      },
                    }}
                    size="small"
                  />
                  <Box sx={{ marginTop: '10px' }}>
                    <DownloadButton
                      title={_t('qr_downloadbtn', 'Download')}
                      onClick={() => downloadEmailText(formik.values)}
                      smallScreenRender="flex"
                      largeScreenRender="flex"
                      height="42px"
                      fontSize="14px"
                      showTitle
                      customColor="#AF4448"
                      disabled={(!formik.values.Email || !!formik.errors.Email)
                        || (!formik.values.Subject || !!formik.errors.Subject)
                        || (!formik.values.Body || !!formik.errors.Body)
                        || (!formik.values.Filename || !!formik.errors.Filename)}
                      fullWidth
                    />
                    {formik.touched.Filename && formik.errors.Filename ? (
                      <div className="form-field-validation-error">
                        {formik.errors.Filename}
                      </div>
                    ) : null}
                  </Box>
                </FormControl>
              </DownloadFormBox>
            </RghtOutputBox>
          </Grid>
        </Grid>
      </StyledBox>
    </JumboBox>
  );
}

// Component Styles
const JumboBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '',
  [theme.breakpoints.up('mobile')]: {
    padding: '0px 0px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '0px 48px',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '0px 48px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    padding: '0px 48px',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: '',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('mobile')]: {
    padding: '0px 0px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '0px 0px',
    maxWidth: '850px',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '0px 0px',
    maxWidth: '1080px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    padding: '0px 0px',
    maxWidth: '1550px',
  },
}));

const LeftInputBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '',
  Width: '100%',
  height: '100%',
  [theme.breakpoints.up('mobile')]: {
    padding: '40px 20px',
    alignItems: 'center',
    maxWidth: '414px',
    marginRight:"0px"

  },
  [theme.breakpoints.up('tablet')]: {
    padding: '56px 0px 40px',
    alignItems: 'flex-start',
    maxWidth: '600px',
    marginRight:"28px"

  },
  [theme.breakpoints.up('desktop')]: {
    padding: '56px 48px 40px',
    alignItems: 'flex-start',
    maxWidth: '710px',
    marginRight:"0px"

  },
  [theme.breakpoints.up('xldesktop')]: {
    padding: '56px 48px 40px',
    alignItems: 'flex-start',
    maxWidth: '100%',
    marginRight:"0px"

  },
}));

const LeftInnerContent = styled(Box)(({ theme }) => ({
  backgroundColor: '',
  [theme.breakpoints.up('mobile')]: {
    maxWidth: '415px',
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '500px',
    width: '100%',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '614px',
    Width: '100%',
  },
  [theme.breakpoints.up('xldesktop')]: {
    maxWidth: '100%',
    width: '100%',
  },
}));

const RghtOutputBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('mobile')]: {
    maxHeight: '414px',
    maxWidth: '100%',
    padding: '40px 20px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '370px',
    maxHeight: '440px',
    padding: '40px 0px 0px 20px',   
     borderTop: '0px',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '370px',
    maxHeight: '440px',
    padding: '40px 48px',
    borderTop: '0px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    maxWidth: '100%',
    maxHeight: '440px',
    padding: '40px 48px',
    borderTop: '0px',
  },
}));

const CoreBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  background: '',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  [theme.breakpoints.up('mobile')]: {
    maxWidth: '414px',
    maxHeight: '290px',
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '274px',
    width: '100%',
    maxHeight: '290px',
    padding: '20px 0px',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '274px',
    width: '100%',
    maxHeight: '290px',
    padding: '20px 12px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    maxWidth: '274px',
    maxHeight: '290px',
    padding: '20px 12px',
    width: '100%',
  },
}));

const EpicenterBox = styled(Box)(() => ({
  background: '',
  maxHeight: '250px',
  maxWidth: '250px',
}));

const DownloadFormBox = styled(Box)(({ theme }) => ({
  background: '',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('mobile')]: {
    maxWidth: '414px',
    width: '100%',
    padding: '20px 0px',
  },
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '274px',
    width: '100%',
    padding: '20px 0px',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '300px',
    width: '100%',
    padding: '20px 0px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    maxWidth: '274px',
    maxHeight: '290px',
    padding: '20px 0px',
    width: '100%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    width: '100%',
  },
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '220px',
    width: '100%',
  },
  [theme.breakpoints.up('xldesktop')]: {
    width: '100%',
  },
}));

EmailQrCode.propTypes = {
  userID: PropTypes.any,
};
