import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchUserInformation, getEmpID, checkSession } from '../../redux/action/qrCodeAction';

import Home from './Home';

const mapStateToProps = (state) => ({
  isSignedIn: state.QR_CodeReducer.isLoggedin,
  userInfo: state.QR_CodeReducer.loggedInUser,
  userID: state.QR_CodeReducer.empID,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUserInformation, getEmpID, checkSession }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Home),
);
