import * as React from "react";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import _t from "../../../utils/helper";
import DownloadButton from "../../appButton/appDownload";
import { createqr, downloadFreeTextQr } from "../../../services/api";
import Palette from "../../../common/palette";

const barcodeManual =
  require("../../../images/defaultQrCodeDisabled.png").default;

const validationSchema = Yup.object({
  contentData: Yup.string()
    .max(250, _t("qr_errormsg_content_maxlength", ""))
    .required(_t("qr_errormsg_content_empty", "")),
  fileName: Yup.string().required(_t("qr_errormsg_filename_empty", "")),
});

export default function FreeTextQrCode(props) {
  const intl = useIntl();
  const [loader, setLoadervalue] = React.useState(false);
  const [qrCodeURL, setQRCodeURL] = React.useState(barcodeManual);
  const { userID } = props;

  const formik = useFormik({
    initialValues: {
      contentData: "",
      fileName: "",
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  function generateFreeTextQR(values) {
    setLoadervalue(true);
    createqr(values.contentData, values.fileName, userID)
      .then((response) => {
        setQRCodeURL(
          `data:image/png;base64,${Buffer.from(
            response.data,
            "binary"
          ).toString("base64")}`
        );
        setLoadervalue(false);
      })
      .catch((error) => {
        alert("Error in QR CODE Generation");
      });
  }

  function downloadFreeText(values) {
    setLoadervalue(true);
    downloadFreeTextQr(values.contentData, values.fileName, userID)
      .then((response) => {
        const { data } = response;
        const imageData = `data:image/png;base64,${Buffer.from(
          data,
          "binary"
        ).toString("base64")}`;
        setQRCodeURL(imageData);
        const downloadLink = document.createElement("a");
        downloadLink.href = imageData;
        downloadLink.download = `Freetext_${values.fileName}`;
        downloadLink.click();
        setLoadervalue(false);
      })
      .catch((error) => {
        alert("Error in QR CODE Download");
      });
  }

  function showLoader() {
    return (
      <TailSpin
        height="inherit"
        width="inherit"
        color={Palette.red.dark}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible
      />
    );
  }

  function renderQRCode() {
    if (loader) {
      return showLoader();
    }
    return (
      <img
        alt=""
        src={qrCodeURL}
        style={{ width: "100%", height: "100%", position: "relative" }}
      />
    );
  }

  return (
    <JumboBox>
      <StyledBox>
        <Grid container>
          <Grid
            item
            mobile={12}
            laptop={8}
            tablet={8}
            desktop={8}
            xldesktop={8}
          >
            <LeftInputBox>
              <LeftInnerContent>
                <Typography
                  fontSize="13px"
                  fontWeight="400"
                  color="text.secondary"
                >
                  <FormattedMessage
                    id="qr_field_members_msg"
                    defaultMessage=""
                    values={{
                      boldify: (chunks) => (
                        <span style={{ fontWeight: "bold" }}>{chunks}</span>
                      ),
                    }}
                  />
                  <br />
                  <FormattedMessage
                    id="qr_field_members_msg_mail"
                    defaultMessage=""
                    values={{
                      link: (chunks) => (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={intl.formatMessage({
                            id: "qr_field_members_msg_mail_link",
                          })}
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </Typography>
                <Box sx={{ marginTop: "32px" }}>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      name="contentData"
                      id="contentData"
                      label="contentData"
                      value={formik.values.contentData}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disableAnimation
                      error={
                        formik.touched.contentData &&
                        Boolean(formik.errors.contentData)
                      }
                      placeholder={intl.formatMessage({
                        id: "qr_enterContent",
                      })}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        height: "56px",
                      }}
                      size="small"
                    />
                    {formik.touched.contentData && formik.errors.contentData ? (
                      <div className="form-field-validation-error">
                        {formik.errors.contentData}
                      </div>
                    ) : null}
                    <StyledButton
                      variant="outlined"
                      sx={{
                        marginTop: "32px",
                        height: "42px",
                        border: "1px solid",
                      }}
                      onClick={() => generateFreeTextQR(formik.values)}
                      disabled={
                        !formik.values.contentData ||
                        !!formik.errors.contentData
                      }
                    >
                      <Typography fontSize="15px" fontWeight="400">
                        {_t("qr_create", "Create")}
                      </Typography>
                    </StyledButton>
                  </FormControl>
                </Box>
              </LeftInnerContent>
            </LeftInputBox>
          </Grid>
          <Grid
            item
            mobile={12}
            laptop={4}
            tablet={4}
            desktop={4}
            xldesktop={4}
          >
            <RghtOutputBox>
              <CoreBox>
                <EpicenterBox>{renderQRCode()}</EpicenterBox>
              </CoreBox>
              <DownloadFormBox>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    name="fileName"
                    id="fileName"
                    label="fileName"
                    value={formik.values.fileName}
                    onChange={formik.handleChange("fileName")}
                    onBlur={formik.handleBlur("fileName")}
                    disableAnimation
                    error={
                      formik.touched.fileName && Boolean(formik.errors.fileName)
                    }
                    placeholder={intl.formatMessage({ id: "qr_fileName" })}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& ::placeholder": {
                        textOverflow: "ellipsis !important",
                        fontSize: {
                          tablet: "12px",
                          mobile: "16px",
                          laptop: "16px",
                          desktop: "16px",
                        },
                      },
                    }}
                    size="small"
                  />
                  <Box sx={{ marginTop: "10px" }}>
                    <DownloadButton
                      title={_t("qr_downloadbtn", "Download")}
                      height="42px"
                      fontSize="14px"
                      customColor="#AF4448"
                      onClick={() => downloadFreeText(formik.values)}
                      smallScreenRender="flex"
                      largeScreenRender="flex"
                      disabled={
                        !formik.values.contentData ||
                        formik.errors.contentData ||
                        !formik.values.fileName
                      }
                      fullWidth
                      showTitle={true}
                    />
                    {formik.touched.fileName && formik.errors.fileName ? (
                      <div className="form-field-validation-error">
                        {formik.errors.fileName}
                      </div>
                    ) : null}
                  </Box>
                </FormControl>
              </DownloadFormBox>
            </RghtOutputBox>
          </Grid>
        </Grid>
      </StyledBox>
    </JumboBox>
  );
}

// Component Styles
const JumboBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "",
  [theme.breakpoints.up("mobile")]: {
    padding: "0px 0px",
  },
  [theme.breakpoints.up("tablet")]: {
    padding: "0px 48px",
    maxHeight: "494px",
  },
  [theme.breakpoints.up("desktop")]: {
    padding: "0px 48px",
    maxHeight: "494px",
  },
  [theme.breakpoints.up("xldesktop")]: {
    padding: "0px 48px",
    maxHeight: "494px",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  background: "",
  width: "100%",
  height: "100%",
  [theme.breakpoints.up("mobile")]: {
    padding: "0px 0px",
  },
  [theme.breakpoints.up("tablet")]: {
    padding: "0px 0px",
    maxWidth: "850px",
  },
  [theme.breakpoints.up("desktop")]: {
    padding: "0px 0px",
    maxWidth: "1080px",
  },
  [theme.breakpoints.up("xldesktop")]: {
    padding: "0px 0px",
    maxWidth: "1550px",
  },
}));

const LeftInputBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "",
  Width: "100%",
  height: "100%",
  [theme.breakpoints.up("mobile")]: {
    padding: "40px 20px",
    alignItems: "center",
    maxWidth: "414px",
    marginRight: "0px",
  },
  [theme.breakpoints.up("tablet")]: {
    padding: "56px 0px 40px",
    alignItems: "flex-start",
    maxWidth: "600px",
    marginRight: "28px",
  },
  [theme.breakpoints.up("desktop")]: {
    padding: "56px 48px 40px",
    alignItems: "flex-start",
    maxWidth: "710px",
    marginRight: "0px",
  },
  [theme.breakpoints.up("xldesktop")]: {
    padding: "56px 48px 40px",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginRight: "0px",
  },
}));

const LeftInnerContent = styled(Box)(({ theme }) => ({
  backgroundColor: "",
  [theme.breakpoints.up("mobile")]: {
    maxWidth: "415px",
    maxHeight: "290px",
    width: "100%",
  },
  [theme.breakpoints.up("tablet")]: {
    maxWidth: "500px",
    width: "100%",
  },
  [theme.breakpoints.up("desktop")]: {
    maxWidth: "614px",
    Width: "100%",
  },
  [theme.breakpoints.up("xldesktop")]: {
    maxWidth: "100%",
    width: "100%",
  },
}));

const RghtOutputBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "",
  width: "100%",
  height: "100%",
  [theme.breakpoints.up("mobile")]: {
    maxHeight: "414px",
    maxWidth: "100%",
    padding: "40px 20px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  [theme.breakpoints.up("tablet")]: {
    maxWidth: "370px",
    maxHeight: "440px",
    padding: "40px 0px 0px 20px",
    borderTop: "0px",
  },
  [theme.breakpoints.up("desktop")]: {
    maxWidth: "370px",
    maxHeight: "440px",
    padding: "40px 48px",
    borderTop: "0px",
  },
  [theme.breakpoints.up("xldesktop")]: {
    maxWidth: "100%",
    maxHeight: "440px",
    padding: "40px 48px",
    borderTop: "0px",
  },
}));

const CoreBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  background: "",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  [theme.breakpoints.up("mobile")]: {
    maxWidth: "414px",
    maxHeight: "290px",
    width: "100%",
  },
  [theme.breakpoints.up("tablet")]: {
    maxWidth: "274px",
    width: "100%",
    maxHeight: "290px",
    padding: "20px 0px",
  },
  [theme.breakpoints.up("desktop")]: {
    maxWidth: "274px",
    width: "100%",
    maxHeight: "290px",
    padding: "20px 12px",
  },
  [theme.breakpoints.up("xldesktop")]: {
    maxWidth: "274px",
    maxHeight: "290px",
    padding: "20px 12px",
    width: "100%",
  },
}));

const EpicenterBox = styled(Box)(() => ({
  background: "",
  maxHeight: "250px",
  maxWidth: "250px",
}));

const DownloadFormBox = styled(Box)(({ theme }) => ({
  background: "",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.up("mobile")]: {
    maxWidth: "414px",
    width: "100%",
    padding: "20px 0px",
  },
  [theme.breakpoints.up("tablet")]: {
    maxWidth: "274px",
    width: "100%",
    padding: "20px 0px",
  },
  [theme.breakpoints.up("desktop")]: {
    maxWidth: "300px",
    width: "100%",
    padding: "20px 0px",
  },
  [theme.breakpoints.up("xldesktop")]: {
    maxWidth: "274px",
    maxHeight: "290px",
    padding: "20px 0px",
    width: "100%",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "100%",
  },
  [theme.breakpoints.up("desktop")]: {
    maxWidth: "220px",
    width: "100%",
  },
  [theme.breakpoints.up("xldesktop")]: {
    width: "100%",
  },
}));

FreeTextQrCode.propTypes = {
  userID: PropTypes.any,
};
