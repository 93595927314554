import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import PropTypes from 'prop-types';
import _t from '../../../utils/helper';
import { listAllQrCode, getQrCode } from '../../../services/api';
import DownloadButton from '../../appButton/appDownload';
import Palette from '../../../common/palette';
import { getUserID } from '../../../utils/appUtils';

export default function ListQrCode(props) {
  const { username } = props.userInfo;
  const [listData, setListData] = React.useState(null);
  React.useEffect(() => {
    let mounted = true;
    listAllQrCode(getUserID(username))
      .then((result) => {
        if (mounted) {
          setListData(result.data);
        }
      })
      .catch((error) => {
        alert('Error in fetching the QR code List');
      });
    return () => {
      mounted = false;
    };
  }, [username]);

  function downloadCard(fileObject) {
    const { s3_filename, filename } = fileObject;
    getQrCode(getUserID(username), s3_filename)
      .then((result) => {
        const imageData = `data:image/png;base64,${Buffer.from(
          result.data,
          'binary',
        ).toString('base64')}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = imageData;
        downloadLink.download = filename;
        downloadLink.click();
      })
      .catch((error) => {
        alert('Error in Downloading QR code');
      });
  }
  function showLoader() {
    return (
      <ProgressIndicatorBox>
        <TailSpin
          height={100}
          width={100}
          color={Palette.red.dark}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible
        />
      </ProgressIndicatorBox>
    );
  }

  function renderBody(data) {
    return Array.isArray(data) && data.length ? (
      data.map((i, index) => (
        <BodyWrapper key={index}>
          <BodyLayer70>
            <Box50>
              <Typography
                fontSize="14x"
                fontWeight="400"
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
              >
                {i.filename}
              </Typography>
            </Box50>
            <Box50>
              <Typography
                fontSize="14x"
                fontWeight="400"
                color="rgba(0, 0, 0, 0.43)"
              >
                {moment(i.date, 'YYYY-MM-DD HH:mm:ss')
                  .add(9, 'hours')
                  .format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </Box50>

          </BodyLayer70>
          <BodyLayer30>
            <Box100>
              <DownloadButton
                title={_t('qr_downloadbtn', 'Download')}
                fullWidth
                smallScreenRender="flex"
                largeScreenRender="flex"
                customColor="#AF4448"
                height="30px"
                onClick={() => downloadCard(i)}
              />
            </Box100>
          </BodyLayer30>

        </BodyWrapper>
      ))
    ) : (
      <h1>{_t('qr_no_history_found', '')}</h1>
    );
  }

  return (
    <JumboBox>
      <StyledBox>
        <Grid container>
          <Grid
            item
            mobile={12}
            laptop={12}
            tablet={12}
            desktop={12}
            xldesktop={12}
          >
            <ParentWrapper>
              <ChildWrapper>
                <Typography
                  fontSize="13px"
                  fontWeight="400"
                  color="text.secondary"
                >
                  {_t('qr_only10_qrs', '')}
                </Typography>
                {listData ? (
                  <Box>
                    <TableHeaderMobile>
                      <Typography
                        fontSize="14x"
                        fontWeight="700"
                        fontFamily="Roboto"
                      >
                        Filename &amp; Date
                      </Typography>
                    </TableHeaderMobile>
                    <TableHeader>
                      <TableHeaderRow70>
                        <TableHeaderRowItem>
                          <Typography
                            fontSize="14x"
                            fontWeight="700"
                          >
                            Filename
                          </Typography>
                        </TableHeaderRowItem>
                        <TableHeaderRowItem>
                          <Typography
                            fontSize="14x"
                            fontWeight="700"
                          >
                            Date Created
                          </Typography>
                        </TableHeaderRowItem>
                      </TableHeaderRow70>
                      <TableHeaderRow30 />
                    </TableHeader>
                    {renderBody(listData)}
                  </Box>
                ) : (
                  showLoader()
                )}

              </ChildWrapper>
            </ParentWrapper>
          </Grid>
        </Grid>
      </StyledBox>
    </JumboBox>
  );
}

// Component Styles
const JumboBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('mobile')]: {
    padding: '0px 0px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '0px 48px',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '0px 48px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    padding: '0px 48px',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('mobile')]: {
    padding: '0px 0px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '0px 0px',
    maxWidth: '850px',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '0px 0px',
    maxWidth: '1080px',
  },
  [theme.breakpoints.up('xldesktop')]: {
    padding: '0px 0px',
    maxWidth: '1550px',
  },
}));

const ParentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  Width: '100%',
  height: '100%',
  [theme.breakpoints.up('mobile')]: {
    padding: '40px 20px',
    alignItems: 'center',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '56px 40px 40px',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '56px 48px 40px',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.up('xldesktop')]: {
    padding: '56px 48px 40px',
    alignItems: 'flex-start',
  },
}));

const ChildWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    width: '100%',
  },
  [theme.breakpoints.up('desktop')]: {
    Width: '100%',
  },
  [theme.breakpoints.up('xldesktop')]: {
    width: '100%',
  },
}));

const TableHeaderMobile = styled(Box)(({ theme }) => ({
  minWidth: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '10px 0px',
  [theme.breakpoints.up('mobile')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('tablet')]: {
    display: 'none',
  },
  [theme.breakpoints.up('desktop')]: {
    display: 'none',
  },
  [theme.breakpoints.up('xldesktop')]: {
    display: 'none',
  },
}));

const TableHeader = styled(Box)(({ theme }) => ({
  minWidth: '100%',
  flexDirection: 'row',
  padding: '10px 0px',
  [theme.breakpoints.up('mobile')]: {
    display: 'none',
  },
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('xldesktop')]: {
    display: 'flex',
  },
}));

const TableHeaderRow70 = styled(Box)(() => ({
  display: 'flex',
  width: '70%',
  flexDirection: 'row',
}));

const TableHeaderRow30 = styled(Box)(() => ({
  display: 'flex',
  width: '30%',
  flexDirection: 'row',
}));

const TableHeaderRowItem = styled(Box)(() => ({
  width: '50%',
}));

const BodyWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '62px',
  padding: '16px 0px',
  borderTop: '1px solid',
  borderTopColor: 'rgba(0, 0, 0, 0.12)',
}));

const BodyLayer70 = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'center',
  width: '70%',
  [theme.breakpoints.up('mobile')]: { flexDirection: 'column', padding: '16px 0px' },
  [theme.breakpoints.up('tablet')]: { flexDirection: 'row' },
  [theme.breakpoints.up('desktop')]: { flexDirection: 'row' },
  [theme.breakpoints.up('xldesktop')]: { flexDirection: 'row' },
}));

const BodyLayer30 = styled(Box)(() => ({
  display: 'flex',
  width: '30%',
}));

const Box50 = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('mobile')]: { width: '100%' },
  [theme.breakpoints.up('tablet')]: { width: '50%' },
  [theme.breakpoints.up('desktop')]: { width: '50%' },
  [theme.breakpoints.up('xldesktop')]: { width: '50%' },
}));

const Box100 = styled(Box)(() => ({
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
}));

const ProgressIndicatorBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '300px',
}));

ListQrCode.propTypes = {
  userInfo: PropTypes.any,
};
