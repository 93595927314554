import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppRouter from './routes/appRoute';
import messages_ja from './translation/ja.json';
import messages_en from './translation/en.json';
import { changeLang } from './redux/action/qrCodeAction';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/ja'; // locale-data for jp
import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en
import './App.css';

const messages = {
  ja: messages_ja,
  en: messages_en,
};

// Main App Compnents code here 
class App extends Component {
  componentDidMount(){
    this.props.changeLang('ja')
  }
  render() {
    const SelectedLanguage = this.props.appLanguage;
    return (
      <IntlProvider locale={SelectedLanguage} messages={messages[SelectedLanguage]} defaultLocale="ja">
        <AppRouter />
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  appLanguage: state.QR_CodeReducer.language,
});

const mapDispatchToProps = {
  changeLang
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

App.propTypes = {
  appLanguage: PropTypes.string,
  changeLang:PropTypes.any
};
