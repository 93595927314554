import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {TailSpin} from "react-loader-spinner";
import Container from "react-bootstrap/Container";
import Palette from '../common/palette';
import {
  captureUrlParameter,
  fetchUserInformation,
} from "../redux/action/qrCodeAction";

import "./appLayout.css";

class Application extends Component {
  componentDidMount() {
    this.props.captureUrlParameter(this.props);
  }

  componentDidUpdate() {
    if (!this.props.userInfo) {
      this.props.fetchUserInformation(
        this.props.token.access_token,
        this.props
      );
    }
  }
  showLoader = () => {
    return (
      <TailSpin
        height={100}
        width={100}
        color={Palette.red.dark}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    );
  };
  render() {
    return <Container className="Button">{this.showLoader()}</Container>;
  }
}

Application.propTypes = {
  isSignedIn: PropTypes.bool,
  captureUrlParameter: PropTypes.any,
  userInfo:PropTypes.any,
  fetchUserInformation:PropTypes.any, 
  token:PropTypes.any,
};

const mapStateToProps = (state) => ({
  isSignedIn: state.QR_CodeReducer.isLoggedin,
  token: state.QR_CodeReducer.az_Oauth2_Params,
  userInfo: state.QR_CodeReducer.loggedInUser,
});

const mapDispatchToProps = {
  captureUrlParameter,
  fetchUserInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Application);
