import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { TailSpin } from 'react-loader-spinner';
import AppConfig from '../../config/appConfig';
import Palette from '../../common/palette';

function Login() {
  const {
    domain, response_type, appClientId, redirectURI,
  } = AppConfig;

  useEffect(() => {
    function ssoSignIn() {
      return window.location = `https://${domain}/login?client_id=${appClientId}&response_type=${response_type}&scope=openid&redirect_uri=${redirectURI}`;
    }
    ssoSignIn();
  });

  function showLoader() {
    return (
      <TailSpin
        height={100}
        width={100}
        color={Palette.red.dark}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible
      />
    );
  }

  return (
    <Container className="center">
      {showLoader()}
    </Container>
  );
}

export default Login;
