import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

const downloadFFEAE5 = require('../../images/Download_FFEAE5.svg').default;
const downloadAF4448 = require('../../images/Download_AF4448.svg').default;
const downloadDisabled = require('../../images/Download_disabled.svg').default;

export default function appDownloadButton(props) {
  const { customColor, disabled, showTitle, customHeight } = props;

  const DownloadButtonTypographyStyled = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: props.fontSize,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: customHeight ? customHeight: '26px',
    lineHeight:"26px",
    [theme.breakpoints.up('mobile')]: {
      display: showTitle ? 'flex' : 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('xldesktop')]: {
      display: 'flex',
    },
  }));

  function startIcon() {
    if (customColor === '#AF4448' && !disabled) {
      return (
        <img
          src={downloadAF4448}
          style={{
            height: '18px',
            width: '18px',
            left: '16.67%',
            right: '16.67%',
            top: '16.67%',
            bottom: '16.67%',
          }}
          alt="mySvgImage"
        />
      );
    }
    if ((customColor === '#AF4448' || customColor === '#FFEAE5') && disabled) {
      return (
        <img
          src={downloadDisabled}
          style={{
            height: '18px',
            width: '18px',
            left: '16.67%',
            right: '16.67%',
            top: '16.67%',
            bottom: '16.67%',
          }}
          alt="mySvgImage"
        />
      );
    }
    if (customColor === '#FFEAE5' && !disabled) {
      return (
        <img
          src={downloadFFEAE5}
          style={{
            height: '18px',
            width: '18px',
            left: '16.67%',
            right: '16.67%',
            top: '16.67%',
            bottom: '16.67%',
          }}
          alt="mySvgImage"
        />
      );
    }
  }
  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="outlined"
        fullWidth={props.fullWidth}
        startIcon={startIcon()}
        sx={{
          display: {
            mobile: props.smallScreenRender,
            laptop: props.largeScreenRender,
            tablet: props.largeScreenRender,
            desktop: props.largeScreenRender,
          },
          '& .MuiButton-startIcon': {
            marginRight: '2px',
            marginLeft: '0px',
          },
          height: props.height,
          padding: '0px 12px',
          border: '1px solid',
          borderRadius: '4px',
          color: customColor,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <DownloadButtonTypographyStyled>
          {props.title}
        </DownloadButtonTypographyStyled>
      </Button>
    </Stack>
  );
}
