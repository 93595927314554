const getAuthSessionInfoCookieValue = (propertyName) => {
  const cookieValue = document.cookie.replace(
    /(?:(?:^|.*;\s*)auth-session-info\s*\\=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
  let val;
  try {
    val = JSON.parse(cookieValue)[propertyName];
  } catch (e) {
    val = {};
  }
  return val;
};

const isAuthSessionInfoCookiePresent = () => document.cookie.indexOf('auth-session-info') >= 0;

const AuthHandler = {
  GetAuthKey: () => (isAuthSessionInfoCookiePresent()
    ? getAuthSessionInfoCookieValue('authSessionState').access_token
    : null),
  GetErrorMessage: (errorObj) => (errorObj.response
    ? errorObj.response.data.ExceptionMessage
    : 'There was an error processing the request'),

  SetCookie: (cookieName, value) => {
    document.cookie = `${cookieName}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/`;
  },
  RemoveCookie: (cookieName) => {
    document.cookie = `${cookieName}=""; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  },
  SetAuthSessionInfoCookie: (authSessionState) => {
    const now = new Date();
    let time = now.getTime();
    const expireIn = authSessionState.expires_in ? authSessionState.expires_in : 300;
    time += expireIn * 1000;
    now.setTime(time);
    document.cookie = `auth-session-info=${JSON.stringify({
      authSessionState,
    })};expires=${now.toUTCString()};path=/`;
  },

  SetAuthSessionTimeforLocalStorage: () => {

  },

  GetAuthSessionInfoCookieValue: (propertyName) => getAuthSessionInfoCookieValue(propertyName),
  ExpireAuthSessionInfoCookie: () => {
    document.cookie = 'auth-session-info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
  },
  IsAuthSessionInfoCookiePresent: isAuthSessionInfoCookiePresent,
  SaveActiveTabIdInCookie: () => {
    if (document.cookie.indexOf('active-tab-id') >= 0) {
    }
  },
};

export default AuthHandler;
