import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function LoginRoute({ component: Component, ...rest }) {
  const isLoggedin = useSelector((state) => state.QR_CodeReducer.isLoggedin);
  if (isLoggedin) {
    return <Route {...rest} render={() => <Redirect to="/home" />} />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default LoginRoute;

LoginRoute.propTypes = {
  component: PropTypes.any,
};
