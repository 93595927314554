const LOGIN = 'LOGIN';
const CAPTURE_URL_PARAMETER = 'CAPTURE_URL_PARAMETER';
const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
const ERROR = 'ERROR';
const LIST_ALL_QRCODE = 'LIST_ALL_QRCODE';
const GET_EMP_ID = 'GET_EMP_ID';
const LOGOUT = 'LOGOUT';
const SETLANGUAGE = 'SETLANGUAGE';

const initialstate = {
  isLoggedin: false,
  az_Oauth2_Params: null,
  loggedInUser: null,
  userQrCodeData: [],
  empID: null,
  language: 'ja',
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoggedin: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedin: false,
        az_Oauth2_Params: null,
        loggedInUser: null,
        userQrCodeData: [],
        empID: null,
      };

    case CAPTURE_URL_PARAMETER:
      return {
        ...state,
        isLoggedin: true,
        az_Oauth2_Params: action.payload,
      };
    case SET_USER_INFORMATION:
      return {
        ...state,
        isLoggedin: true,
        loggedInUser: action.payload,
      };
    case ERROR:
      return {
        ...state,
        isLoggedin: false,
        loggedInUser: null,
        az_Oauth2_Params: false,
      };
    case LIST_ALL_QRCODE:
      return {
        ...state,
        userQrCodeData: action.payload,
      };
    case GET_EMP_ID:
      return {
        ...state,
        empID: action.payload,
      };
    case SETLANGUAGE:
      return {
        ...state,
        language: action.data,
      };
    default:
      return state;
  }
};
