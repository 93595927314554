import { getUrlParameter, getUserID } from '../../utils/appUtils';
import { getUserInfo, listAllQrCode } from '../../services/api';
import AuthHandler from '../../utils/authHandler';

const LOGIN = 'LOGIN';
const CAPTURE_URL_PARAMETER = 'CAPTURE_URL_PARAMETER';
const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
const LIST_ALL_QRCODE = 'LIST_ALL_QRCODE';
const GET_EMP_ID = 'GET_EMP_ID';
const ERROR = 'ERROR';
const LOGOUT = 'LOGOUT';
const SETLANGUAGE = 'SETLANGUAGE';

export const login = () => (dispatch) => {
  dispatch({ type: LOGIN, payload: true });
};

export const captureUrlParameter = (props) => (dispatch) => {
  if (getUrlParameter()) {
    const now = new Date();
    const items = {
      value: getUrlParameter(),
      expiry: now.getTime() + 1000000,
    };
    localStorage.setItem('key', JSON.stringify(items));
    AuthHandler.SetAuthSessionInfoCookie(getUrlParameter());
    dispatch({
      type: CAPTURE_URL_PARAMETER,
      payload: getUrlParameter(),
    });
  } else {
    props.history.push('/');
  }
};

export const fetchUserInformation = (token, props) => (dispatch) => {
  getUserInfo(token).then((result) => {
    localStorage.setItem('isAuth', true);
    props.history.push('/home');
    dispatch({
      type: SET_USER_INFORMATION,
      payload: result.data,
    });
  }).catch((err) => {
    dispatch({
      type: ERROR,
      payload: err,
    });
  });
};

export const listAllBarCode = (userID) => {
  const extractID = getUserID(userID);
  return (dispatch) => {
    listAllQrCode(extractID).then((result) => {
      dispatch({
        type: LIST_ALL_QRCODE,
        payload: result.data,
      });
    }).catch((err) => {
    });
  };
};

export const getEmpID = (userID) => {
  const extractID = getUserID(userID);
  return (dispatch) => {
    if (extractID.length) {
      dispatch({
        type: GET_EMP_ID,
        payload: extractID,
      });
    } else {
      alert('Cannot Get ID');
    }
  };
};

export const checkSession = () => (dispatch) => {
  const now = new Date();
  const itemStr = localStorage.getItem('key');
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  if (now.getTime() > item.expiry) {
    localStorage.removeItem('persist:QR');
    localStorage.removeItem('key');
    AuthHandler.ExpireAuthSessionInfoCookie();
    dispatch({ type: LOGOUT });
  } else {

  }
};

export const changeLang = (lang) => {
  localStorage.setItem('lang', lang);
  return (dispatch) => {
    dispatch({
      type: SETLANGUAGE,
      data: lang,
    });
  };
};
