/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ExperimentalAppBar from './appBar';
import DownloadButton from '../appButton/appDownload';
import _t from '../../utils/helper';
import appConfig from '../../config/appConfig';
import { downloadBusinessCardData } from '../../services/api';
import Appmodal from '../appModal/appModal';


const AppLogo = require('../../images/logo/2D_Barcode_Logo_JP.svg').default;
const AppLogoEn = require('../../images/logo/2D_Barcode_Logo_EN.svg').default;
const AppLogoMobile = require('../../images/logo/2D_Barcode_Logo_JP_Mobile.svg').default;
const AppLogoEnMobile = require('../../images/logo/2D_Barcode_Logo_EN_Mobile.svg').default;

const LeftButtonGroupStyled = styled(ButtonGroup)(() => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  width: '216px',
  height: '56px',
}));

const AppLogoStyled = styled('img')(({theme}) => ({
  padding: '0px',
  [theme.breakpoints.up('mobile')]: {
    display: 'none',
  },
  [theme.breakpoints.up('tablet')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('desktop')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('xldesktop')]: {
    display: 'flex',
  },
}));

const AppLogoStyledMobile = styled('img')(({theme}) => ({
  padding: '0px',
  [theme.breakpoints.up('mobile')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('tablet')]: {
    display: 'none',
  },
  [theme.breakpoints.up('desktop')]: {
    display: 'none',
  },
  [theme.breakpoints.up('xldesktop')]: {
    display: 'none',
  },
}));

const AppAvatarStyled = styled(Avatar)(({ theme }) => ({
  height: '24px',
  width: '24px',
  padding: 2,
  background: theme.palette.primary.lightAccent,
  color: theme.palette.primary.dark,
}));

const RightButtonGroupStyled = styled(ButtonGroup)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: '56px',
}));

// Main Function based Component Starts here
export default function AppBarChildren(props) {
  const [warningState, setWarningState] = React.useState(false);
  const [downloaded, setDownloaded] = React.useState(true);
  const [downloadMessage, setDownloadMessage] = React.useState(
    _t('qr_download', 'Download Business Card QR Code'),
  );
  const presentLanguage = useSelector((state) => state.QR_CodeReducer.language);
  const { userInfo, toggleDrawer, userID } = props;

  // Get the Avatar Alias Name
  const getAlias = () => {
    const AliasName = userInfo
      ? `${userInfo.given_name[0]}${userInfo.family_name[0]}`
      : '';
    return AliasName;
  };

  // Logic to extract User Id from User Name

  const PullUserID = () => {
    const userName = userInfo.username;
    const splitUserName = userName.split('_');
    const extractId = splitUserName[1];
    return extractId;
  };

  // Logic to Download the Business card
  const DownloadBusinessCard = () => {
    const UID = userID || PullUserID();
    const crqrURL = `${appConfig.crqrURL}?`;
    const requestType = 'getcode';
    const qrcodeType = 'vcard';
    const action = 'download';
    const qrcodeContent = null;
    const fileName = null;
    const empid = UID;
    const emailTo = null;
    const emailTittle = null;
    const emailBody = null;
    const now = `${crqrURL}Request_type=${requestType}&qrcode_type=${qrcodeType}&qrcode_content=${qrcodeContent}&file_name=${fileName}&empid=${empid}&emailTo=${emailTo}&emailTittle=${emailTittle}&emailBody=${emailBody}&action=${action}`;
    setDownloadMessage(_t('qr_download_biz_card', ''));
    setDownloaded(false);
    downloadBusinessCardData(now)
      .then((response) => {
        const { data, headers } = response;
        if (headers['content-type'] === 'application/json') {
          setWarningState(true);
        } else {
          const fileNameDownload = headers['qr-code-filename-download'];
          const imageData = `data:image/png;base64,${Buffer.from(
            data,
            'binary',
          ).toString('base64')}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = imageData;
          downloadLink.download = fileNameDownload;
          downloadLink.click();
          setWarningState(false);
        }
      })
      .catch((error) => {
        console.log('Error Ocurred When Downloading', error);
      })
      .then(() => {
        setDownloaded(true);
        setDownloadMessage(_t('qr_download', 'Download Business Card QR Code'));
      });
  };

  const appBarLeftChildren = () => (
    <LeftButtonGroupStyled>
      <AppLogoStyled src={ presentLanguage === 'ja' ? AppLogo: AppLogoEn} alt="AZ 2D Barcode App Logo" />
      <AppLogoStyledMobile src={ presentLanguage === 'ja' ? AppLogoMobile: AppLogoEnMobile} alt="AZ 2D Barcode App Logo" />
    </LeftButtonGroupStyled>
  );

  const appBarRightChildren = () => (
    <RightButtonGroupStyled>
      <DownloadButton
        title={downloadMessage}
        smallScreenRender="none"
        largeScreenRender="flex"
        height="30px"
        fontSize="13px"
        showTitle
        customColor="#FFEAE5"
        onClick={() => DownloadBusinessCard()}
      />
      <IconButton
        size="small"
        edge="end"
        color="inherit"
        aria-label="menu"
        sx={{ paddingLeft: 2 }}
        onClick={() => toggleDrawer(true)}
      >
        <AppAvatarStyled>
          <Typography
            fontWeight={400}
            fontSize={"11px"}
            lineHeight={"20px"}
            letterSpacing="0.14px"
          >
            {getAlias()}
          </Typography>
        </AppAvatarStyled>
      </IconButton>
    </RightButtonGroupStyled>
  );

  return (
    <>
      <ExperimentalAppBar
        leftChildren={appBarLeftChildren()}
        rightChildren={appBarRightChildren()}
      />
      <Appmodal
        open={warningState}
        closeModal={() => setWarningState(false)}
        title={_t('qr_attention', '')}
        description={_t('qr_no_business_card_warning', '')}
        buttonTitle={_t('qr_close_button', '')}
      />

    </>
  );
}

AppBarChildren.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  userID: PropTypes.string,
};
